<!--
 * @Author: your name
 * @Date: 2021-01-05 09:45:31
 * @LastEditTime: 2021-01-09 14:10:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \guanwang\zhongwang-index-vue\src\views\pages\profession\profession.vue
-->
<template>
  <div>
    <banner bg-name="banner-business.png" desc-type="banner-descTwo.png"></banner>
    <div>
      <div class="introduNav">
        <!-- 标签页 -->
        <div class="nav">
          <ul class="nav-list">
            <li
              class="nav-item"
              :class="{ active: activeIndex == 0 }"
              @click="handleClickNavItem(0)"
            >
              面向园区
            </li>
            <li
              class="nav-item"
              :class="{ active: activeIndex == 1 }"
              @click="handleClickNavItem(1)"
            >
              面向政府
            </li>
            <li
              class="nav-item"
              :class="{ active: activeIndex == 2 }"
              @click="handleClickNavItem(2)"
            >
              面向企业
            </li>
          </ul>
          <div class="nav-bar">
            <div class="nav-slider" :style="{ left: sliderLeft }"></div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="proFu">
          <!-- <div
            :style="{ height: activeIndex == 0 || activeIndex == 1 ? '1700px' : '2200px' }"
          ></div> -->
          <div v-show="activeIndex == 0" class="proCoreDiv">
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">智慧园区</p>
                <p class="proCoreContentChi">
                  智慧园区平台，通过云大物移智等先进的技术手段，加强园区内部的互动沟通和管理能力，在更大范围内提高园区知名度、服务水平、管理水平；加强园区管委会、园区企业等各个方面的资源整合能力，把园区内各方的专长资源加以整合推广，提升园区整体品牌形象。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/3690.png" alt="" />
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreImg">
                <img src="~@/assets/images/3555.png" alt="" />
              </div>
              <div class="proCoreContent">
                <p class="proCoreContentTItle">智慧水务</p>
                <p class="proCoreContentChi">
                  系统以云计算、大数据、物联网、工业自动化为基础，逐步实现水务管理的智慧化。智慧水务包括三个层次，即设备层、数据传输层以及平台层。在实现基础设施数字化一定规模后，构建以智慧水务平台为核心的智慧化运营体系将成为智慧水务企业的业务转型重心，即实现数字化向智慧化的过渡。
                </p>
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">智慧能耗</p>
                <p class="proCoreContentChi">
                  采用物联网、大数据、云计算、移动互联网及人工智能等现代信息技术，依托公有云和私有云，推出环保用电监管云平台、污染治理设施监管平台、视频监控平台、污染物监测平台、污水流量测量平台，该体系构成了全系统的环保设备及环境监测云平台。通过云数据分析并实时监管企业的生产状态，实现了从“点末端监控”向“全过程监控”扩展。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/302.png" alt="" />
              </div>
            </div>
          </div>
          <div v-show="activeIndex == 1" class="proCoreDiv">
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">GIS网格化</p>
                <p class="proCoreContentChi">
                  社区网格化管理系统依托统一的城市管理以及数字化的平台，将城市管理辖区按照一定的标准划分成为单元网格。通过加强对单元网格的部件和事件巡查，建立一种监督和处置互相分离的形式。对于政府来说的主要优势是政府能够主动发现，及时处理，加强政府对城市的管理能力和处理速度，将问题解决在居民投诉之前。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/3893.png" alt="" />
              </div>
            </div>
            <div class="proCore" id="ring">
              <div class="proCoreImg">
                <img src="~@/assets/images/3887.png" alt="" />
              </div>
              <div class="proCoreContent">
                <p class="proCoreContentTItle">智慧社区</p>
                <p class="proCoreContentChi">
                  通过综合运用现代科学技术，整合区域人、地、物、情、事、组织和房屋等信息，统筹公共管理、公共服务和商业服务等资源，以智慧社区综合信息服务平台为支撑，依托适度领先的基础设施建设，提升社区治理和小区管理现代化，促进公共服务和便民利民服务智能化的一种社区管理和服务创新模式，也是实现新型城镇化发展目标和社区服务体系建设目标的重要举措之一。
                </p>
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">智慧党建</p>
                <p class="proCoreContentChi">
                  结合各级党政机关部门的实际情况，依托互联网、云计算、可视化音视频通信、数据交互、远程共享和协助等多方式的交流技术建设的
                  “智慧党建平台”
                  ，可实现党员学习监管、考勤签到、视频交互沟通、远程授课、党建信息汇集与共享等功能，进一步规范基层党组织的凝聚力和战斗力，为党建事业提供坚强的组织保证。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/3888.png" alt="" />
              </div>
            </div>
          </div>
          <div v-show="activeIndex == 2" class="proCoreDiv">
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">协同办公</p>
                <p class="proCoreContentChi">
                  企业内搜到即聊，云端消息多端同步。加入群聊可查阅历史信息，快速融入团队。投票、问卷、定时通知等丰富功能。企业数据内外隔离，纵深防御安全放心。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/3892.png" alt="" />
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreImg">
                <img src="~@/assets/images/3891.png" alt="" />
              </div>
              <div class="proCoreContent">
                <p class="proCoreContentTItle">安全管家</p>
                <p class="proCoreContentChi">
                  “安全管家”根据实用的安全管理理论和方法，结合中国安全监管的特点和企业安全管理的水平，借助互联网、移动通信、物联网、云服务和大数据等技术，以系统化的安全咨询方案为基础，针对企业安全风险特点，与生产运营对接，精准解决安全生产制度落地、员工安全培训教育、安全生产风险实时监控和科学化决策等具有挑战的安全管理难题，实现安全管理的革命性突破，为企业提供实用、便捷和有效的安全管理定量化解决方案。
                </p>
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreContent">
                <p class="proCoreContentTItle">环保管家</p>
                <p class="proCoreContentChi">
                  严谨的流程体系，支持多种辨识方法，建立规范的环境因素档案，输出符合体系要求的环境因素清单。为工业废水、废气、噪音、废料管理提供检测标准，制定检测计划，对重点位置实行定期检测提醒，输出检测记录台账，方便查看和统计。通过对废弃物的信息维护，实现网上申报处置，实时结算，快速处理。并生成废弃物统计清单，方便查看。
                </p>
              </div>
              <div class="proCoreImg">
                <img src="~@/assets/images/3890.png" alt="" />
              </div>
            </div>
            <div class="proCore">
              <div class="proCoreImg">
                <img src="~@/assets/images/3889.png" alt="" />
              </div>
              <div class="proCoreContent">
                <p class="proCoreContentTItle">职业健康管家</p>
                <p class="proCoreContentChi">
                  通过对作业场所、人员岗位职业危害因素的维护，针对岗位性质进行检测与检测，并按岗配置劳动防护用品，对员工定期进行体检及职业病检查，建立职业健康管理档案；
                  一人一档职业卫生档案；包含人员基本信息管理，员工历史体检记录管理，员工调离岗记录管理，员工职业病诊治记录等；
                  劳动防护用品管理；管理劳动防护用品品类及库存数量，进出库记录，领用及报废记录等，需定期检查的防护用品系统会自动发送提醒。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from '@/components/Banner';
export default {
  components: {
    Banner
  },
  data() {
    return {
      sliderLeft: '70px',
      activeIndex: 0,
      rouId: ''
    };
  },
  methods: {
    handleClickNavItem(index) {
      this.activeIndex = index;
      let str = '';
      switch (index) {
        case 0:
          str = '70px';
          break;
        case 1:
          str = '310px';
          break;
        case 2:
          str = '550px';
          break;
        default:
          break;
      }
      this.sliderLeft = str;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.rouId = +this.$route.query.params;
      this.handleClickNavItem(this.rouId || 0);
      if (this.rouId) {
        window.scrollTo(600, 600);
      }
    });
  },

  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal.query.params !== oldVal.query.params) {
            this.rouId = +newVal.query.params;
            this.handleClickNavItem(this.rouId);
            window.scrollTo(600, 600);
          }
          return newVal;
        });
      },
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.introduNav {
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto 200px;
  position: relative;
  .nav {
    z-index: 100px;
    position: sticky;
    top: 0;
    margin: 30px auto 0;
    background-color: #fff;
    height: 48px;
    line-height: 48px;
    .nav-bar {
      z-index: 100px;

      position: relative;
      width: 1200px;
      height: 2px;
      margin: -2px auto 0;
      background-color: #f4f4f4;
      border-radius: 9px;
      .nav-slider {
        position: absolute;
        // top: 10px;
        height: 2px;
        width: 100px;
        border-radius: 9px;
        background-color: #1d7df8;
        transition: all 0.2s ease-out;
      }
    }

    .nav-list {
      z-index: 100px;

      display: flex;
      width: 720px;
      .nav-item {
        display: flex;
        flex: 1;
        color: #666666;
        cursor: pointer;
        &:hover {
          font-size: 18px;
          color: #1d7df8;
        }
        &:nth-child(1) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(2) {
          align-items: center;
          justify-content: center;
        }

        &:nth-child(3) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(4) {
          align-items: center;
          justify-content: center;
        }
        &:nth-child(5) {
          align-items: center;
          justify-content: center;
        }
      }
      .active {
        font-size: 18px;
        color: #1d7df8;
      }
    }
  }
  .proFu {
    // position: relative;

    .proCoreDiv {
      transition: all 0.5s ease-out;
      // box-sizing: border-box;
      // height: 3000px;
      // position: absolute;
      // top: 0;
      // left: 0;
      .proCore {
        // width: 1200px;
        // height: 500px;
        margin-top: 150px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .proCoreContent {
          width: 330px;
          p {
            font-family: Microsoft YaHei;
            color: #333333;
          }
          .proCoreContentTItle {
            font-weight: bold;
            font-size: 24px;
          }
          .proCoreContentChi {
            margin-top: 30px;
            font-weight: 400;
            font-size: 16px;
          }
        }
        .proCoreImg {
          img {
            width: 670px;
            height: 420px;
          }
        }
      }
    }
  }
}
</style>
